import React, {Fragment} from 'react';
import SEO from '../Components/seo';
import ResultPage from '../Components/ResultPage';
import {Text} from '../Widgets';

const NotFoundPage = () => (
  <Fragment>
    <SEO title="404: Not found" />
    <ResultPage image="/images/error-404.png">
      <Fragment>
        <Text weight="600" style={{marginBottom: 5}}>
          很抱歉，此頁面已不存在！
        </Text>
        <Text weight="600" style={{marginBottom: 5}}>
          歡迎繼續瀏覽選購其他商品！
        </Text>
      </Fragment>
    </ResultPage>
  </Fragment>
);

export default NotFoundPage;
