import React, {Fragment} from 'react';
import Image from './Image';

export default function ResultPage({image, children}) {
  return (
    <Fragment>
      <div
        style={{
          // position: 'absolute',
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 40,
        }}>
        <Image
          src={image}
          alt="result"
          width="70%"
          style={{maxWidth: 650, marginBottom: 30}}
        />
        {children}
      </div>
    </Fragment>
  );
}
